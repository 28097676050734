import React from "react";
import "./DashHeader.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { HiOutlineHome } from "react-icons/hi";
import { FaHardDrive } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaChartLine } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";
import { IoIosLock } from "react-icons/io";
import { MdSell } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";

const DashHeader = () => {
  const dispatch = useDispatch();
  function handleLogout() {
    dispatch(
      updateAuth({
        isAuth: false,
        userId: null,
        jwtToken: null,
        ipAddress: null,
      })
    );
  }

  const { userId, status } = useSelector((state) => state.Auth);
  return (
    <div>
      <div className="topbar d-flex align-items-center">
        <nav className="navbar navbar-expand">
          <div className="topbar-logo-header">
            <div className="">
              <img
                src="./assets/image/logo/logo.svg"
                className="logo"
                alt="logo icon"
              />
            </div>
          </div>
          <div className="Id_Profile">
            <h6 className="six">
              ID:-
              {/* <img
                src="./assets/image/id_red.png"
                className="id_Inactive"
              />{" "} */}
              {status === "Active" ? (
                <img
                  src="./assets/image/id_green.png"
                  className="id_Inactive"
                />
              ) : (
                <img src="./assets/image/id_red.png" className="id_Inactive" />
              )}
              {userId}
            </h6>{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a className="dahHeaderemg">
              <img src="./assets/image/profile.png" />
            </a>
          </div>
        </nav>
      </div>

      <Navbar
        collapseOnSelect
        expand="xl"
        className="bgNav brdbtm"
        style={{ background: "#1F1F1F", top: "60px" }}
        fixed="top">
        <Container>
          <Navbar.Brand className="navbar_brandDash">
            <Link to="/DashBoard">
              <img
                src="./assets/image/logo/logo.svg"
                className="logo w-50"
                alt="logo icon"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="align-items-center">
              <Link className="lenk" to="/DashBoard">
                <HiOutlineHome /> Dashboard
              </Link>
              {/* <Link className="lenk" to="/Adjustment">
                <FaHardDrive /> Adjustment
              </Link>
              <Link className="lenk" to="/Sell_Rate_Chart">
                <FaUserFriends /> Buy/Sell Rate Chart
              </Link> */}
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <FaChartLine /> Activate/Upgrade
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Activate">Activate/Upgrade</Link>
                    {/* <Link to="/ActivateByFund">
                      Activate/Upgrade By EBT Assets
                    </Link> */}
                    <Link to="/History">Activate/Upgrade History</Link>
                    <Link to="/IncomeLimit">Income Limit History</Link>
                    {/* <Link to="/ActivationByFundReport">
                      {" "}
                      Activate/Upgrade By EBT Assets History
                    </Link> */}
                    {/* <Link to="/AdminFundExpiredHistory">
                      {" "}
                      Admin Fund Expire History
                    </Link> */}
                  </div>
                </div>
              </span>
              {/* <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Fund
                  </button>
                  <div className="dropdown-content">
                    <Link to="/transferfund">Transfer Fund</Link>
                    <Link to="/transferHistory">
                      Transfer History
                    </Link>
                    <Link to="/receive_History">
                      Receive History
                    </Link>
                  </div>
                </div>
              </span> */}
              {/* <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Activaion/Upgrade
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Activate_Upgrade">Activaion/Upgrade</Link>
                    <Link to="/Activate_Upgrade_History">
                      Activaion History
                    </Link>
                  </div>
                </div>
              </span> */}
              {/* <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Buy
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Buy_Token">Buy Token</Link>
                    <Link to="/Buy_Token_Report">Buy Token Report</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn"><MdSell /> Sell</button>
                  <div className="dropdown-content">
                    <Link to="/Sell_Token">Sell Token</Link>
                    <Link to="/Sell_Token_Report">Sell Token Report</Link>
                    <Link to="/Auto_Sell_Token_Report">
                      Auto Sell Token Report
                    </Link>
                  </div>
                </div>
              </span> */}
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Genealogy
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Tree_View">Matching Tree</Link>
                    <Link to="/Direct_User_List">Direct User List</Link>
                    <Link to="/Level_Details">Level Details</Link>
                    <Link to="/Downline">Downline</Link>
                    <Link to="/Direct_Leg_Business">Direct Business</Link>
                    <Link to="/MatchingBusiness">Matching Business</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Reports
                  </button>
                  <div className="dropdown-content jhdcjsdqkijioj">
                    <Link to="/Direct_Income">Direct Income</Link>
                    <Link to="/Matching_income">Daily Matching Income</Link>
                    <Link to="/Gross_Matching_income">
                      Gross Matching Income
                    </Link>
                    <Link to="/Roi_Income">ROI Income</Link>
                    <Link to="/ROI_Level_Income">ROI Level Income</Link>
                    <Link to="/Daily_all_income">Daily All Income</Link>
                    {/* <Link to="/Differential_income">Differential Income</Link> */}
                    {/* <Link to="/Booster_income">Booster Income</Link> */}
                    {/* <Link to="/Profit_Share_Income">Profit Share Income</Link>
                    <Link to="/Salary_income">Salary Income</Link> */}
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Withdrawal
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Withdrawal">Withdrawal</Link>
                    <Link to="/Withdrawal_History">Withdrawal History</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropdbtn">
                    <LuFileEdit /> Game Amount
                  </button>
                  <div className="dropdown-content">
                    <Link to="/GameAmountTransfer"> P2P Transfer</Link>
                    <Link to="/GameAmountTransferHistory"> Game Amount Transfer History</Link>
                    <Link to="/GameAmountReceivedHistory"> Game Amount Received History</Link>
                  </div>
                </div>
              </span>
              <span className="lenk">
                <div className="dropdown">
                  <button className="dropbtn">
                    <LuFileEdit /> Helpdesk
                  </button>
                  <div className="dropdown-content">
                    <Link to="/Helpdesk">Helpdesk</Link>
                  </div>
                </div>
              </span>

              <a className="lenk" href="#" onClick={() => handleLogout()}>
                <IoIosLock /> Sign Out
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default DashHeader;
